import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);
const routes = [
    {
        path: '/',
        name: 'home',
        props: true,
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
        children: [
            {
                path: 'register',
                name: 'register',
                props: true,
                component: () => import(/* webpackChunkName: "register" */ '../components/RegisterDialog.vue')
            },
            {
                path: 'login',
                name: 'login',
                props: true,
                component: () => import(/* webpackChunkName: "login" */ '../components/LoginDialog.vue')
            }
        ]
    },
    {
        path: '/activate/:token',
        name: 'activate',
        props: true,
        component: () => import(/* webpackChunkName: "activate" */ '../views/account/Activate.vue')
    },
    {
        path: '/incidents',
        props: true,
        component: () => import(/* webpackChunkName: "incidents" */ '../views/IncidentsHome.vue'),
        children: [
            {
                path: '/',
                name: 'incidents',
                props: true,
                component: () => import(/* webpackChunkName: "incidents" */ '../views/Incidents.vue')
            },
            {
                path: 'report',
                name: 'report',
                props: true,
                component: () => import(/* webpackChunkName: "report" */ '../views/report/First.vue')
            },
            {
                path: 'citizen-reports',
                name: 'citizen-reports',
                props: true,
                component: () => import(/* webpackChunkName: "citizen-reports" */ '../views/CitizenReports.vue')
            },
            {
                path: 'user/citizen-reports',
                name: 'user.citizen-reports',
                props: true,
                component: () => import(/* webpackChunkName: "user.citizen-reports" */ '../views/user/citizen-reports/Main.vue')
            }
        ]
    },
    {
        path: '/petitions',
        name: 'petitions',
        props: true,
        component: () => import(/* webpackChunkName: "incidents" */ '../views/Petitions.vue')
    },
    {
        path: '/booking',
        name: 'booking',
        props: true,
        component: () => import(/* webpackChunkName: "incidents" */ '../views/Booking.vue')
    }
];
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});
export default router;
