import { defineComponent } from '@vue/composition-api';
import AuthMenu from '@/components/AuthMenu.vue';
export default defineComponent({
    components: {
        AuthMenu
    },
    props: {
        color: {
            type: String
        },
        title: {
            type: String
        },
        to: {
            type: Object
        }
    }
});
