import Axios from 'axios';
import { API } from '@/config';
import store from '@/store';
const axios = Axios.create({
    baseURL: API.baseURL
});
axios.interceptors.request.use(config => {
    if (!store.getters['auth/isGuest'] && config.headers) {
        config.headers.Authorization = 'bearer ' + store.state.auth.token;
    }
    return config;
});
export default axios;
