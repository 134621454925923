import { envStrict } from '@/lib/env';
export const API = {
    baseURL: envStrict('VUE_APP_API_URL')
};
export const imageCaptureConfig = {
    maxCaptures: 3,
    mediaTrackSettings: {
        width: 480,
        height: 320
    }
};
export const baseMapConfig = {
    center: [24.9729303933, 45.8524312742],
    zoom: 6,
    minZoom: 6,
    maxZoom: 25,
    extent: [
        [
            [19.88525390625, 43.51668853502906],
            [29.9267578125, 43.51668853502906],
            [29.9267578125, 48.44377831058802],
            [19.88525390625, 48.44377831058802],
            [19.88525390625, 43.51668853502906]
        ]
    ]
};
