import Vue from 'vue';
import Vuex from 'vuex';
import { module as alerts } from './alerts';
import { module as auth } from './auth';
import { module as locations } from './locations';
Vue.use(Vuex);
export default new Vuex.Store({
    modules: {
        alerts,
        auth,
        locations
    }
});
