const getUserLocations = () => {
    const locations = localStorage.getItem('locations');
    return locations ? (JSON.parse(locations) || []) : [];
};
export const module = {
    namespaced: true,
    state: {
        locations: getUserLocations()
    },
    getters: {
        locations: (state) => state.locations
    },
    mutations: {
        SET_LOCATION(state, { county, locality }) {
            if (!state.locations.find(location => location.county._id === county._id && location.locality._id === locality._id)) {
                state.locations.push({ county, locality });
                localStorage.setItem('locations', JSON.stringify(state.locations));
            }
        }
    },
    actions: {
        setLocation({ commit }, { county, locality }) {
            commit('SET_LOCATION', { county, locality });
        }
    }
};
