import { defineComponent, provide, watch } from '@vue/composition-api';
import { provideToast, useToast } from 'vue-toastification/composition';
import 'vue-toastification/dist/index.css';
import { AxiosKey, StoreKey } from '@/symbols';
import store from '@/store';
import axios from '@/plugins/axios';
export default defineComponent({
    setup() {
        provide(StoreKey, store);
        provide(AxiosKey, axios);
        provideToast();
        const toast = useToast();
        watch(() => store.state.alerts.alert, alert => {
            if (alert) {
                toast[alert.type](alert.message);
                store.dispatch('alerts/removeAlert');
            }
        });
    }
});
