import jwt from 'jwt-decode';
import { differenceInSeconds, fromUnixTime, isPast as isPastDate, isValid as isValidDate } from 'date-fns';
export const getExpiryTimeout = ({ preceding }) => {
    return (now, exp) => {
        return Math.max(0, differenceInSeconds(exp, now) - preceding) * 1000;
    };
};
export const extractUserJwtData = (token) => {
    const data = jwt(token);
    if (!('exp' in data)) {
        throw new Error('Token is does not have an exp claim.');
    }
    const exp = fromUnixTime(data.exp);
    if (!isValidDate(exp)) {
        throw new Error('Token exp claim is invalid.');
    }
    if (isPastDate(exp)) {
        throw new Error('Token claim is expired.');
    }
    return {
        ...data,
        exp
    };
};
