import { inject } from '@vue/composition-api';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
export const range = (size, startAt = 0) => [...Array(size).keys()].map(i => i + startAt);
export const injectStrict = (key, fallback) => {
    const provide = inject(key, fallback);
    if (!provide) {
        throw new Error(`Could not resolve ${key.description}`);
    }
    return provide;
};
export const extractFieldsData = (fields) => Object.fromEntries(Object.keys(fields).map((field) => [field, fields[field].value]));
export const dispatchAxiosErrorAlert = (store, err) => store.dispatch('alerts/setAlert', {
    type: 'error',
    message: err.response?.data?.message || err.response?.statusText || err.message
});
export const nullify = (what) => what === undefined ? null : what;
export const formatDate = (dateStr, f) => {
    const date = dateStr instanceof Date ? dateStr : parseISO(dateStr);
    return date && !isNaN(date.getTime()) ? format(date, f) : '';
};
