import { defineComponent, computed } from '@vue/composition-api';
import { StoreKey } from '@/symbols';
import { injectStrict } from '@/lib/misc';
export default defineComponent({
    setup() {
        const store = injectStrict(StoreKey);
        const isGuest = computed(() => store.getters['auth/isGuest']);
        const logout = async () => {
            await store.dispatch('auth/logout');
        };
        return {
            isGuest,
            logout
        };
    }
});
