export const module = {
    namespaced: true,
    state: {
        alert: null
    },
    mutations: {
        SET_ALERT(state, alert) {
            state.alert = alert;
        },
        REMOVE_ALERT(state) {
            state.alert = null;
        }
    },
    actions: {
        setAlert({ commit }, alert) {
            commit('SET_ALERT', alert);
        },
        removeAlert({ commit }) {
            commit('REMOVE_ALERT');
        }
    }
};
